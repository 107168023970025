
import { mapMutations, mapState } from 'vuex';
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState('periodicReport', ['currentReport'])
  },
  watch: {
    'currentReport.kpis': function(newValue, oldValue) {}
  },
  methods: {
    ...mapMutations('periodicReport', ['setCurrentReport'])
  }
};
